import React, { useEffect } from "react";

function Footer(props) {

    return (
        <footer className="main-footer">
            <strong> © Copyright {new Date().getFullYear()} <a href="http://cause.id">PT Sehat Dengan Olahraga</a></strong>
        </footer>
    );
}
export default Footer;
