import React from "react";
import { Helmet } from "react-helmet";

const StyleAdminLte = (props) => (
    <Helmet>
        <title>Admin - Store Cause.id</title>
        <link rel="stylesheet" href="/css/adminlte.css" />
    </Helmet>
);

export default StyleAdminLte;