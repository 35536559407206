import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import Main from './Router';
import { Provider } from 'react-redux';
import { store } from './state/store';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tooltip';
import CartContexWrapper from './context/CartContextWrapper';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { GoogleOAuthProvider } from '@react-oauth/google';
import modal from './utility/modal';

class Index extends Component {
    render() {
      return (
        <GoogleOAuthProvider clientId={modal.clientId}>
          <Provider store={store}>
            <BrowserRouter basename="/">
              <CartContexWrapper>
              <Route component={Main} />
              </CartContexWrapper>
              <ToastContainer/>
            </BrowserRouter>
            
          </Provider>
        </GoogleOAuthProvider>
      );
    }
  }
  ReactDOM.render(<Index />, document.getElementById('root'));