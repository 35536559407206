import Swal from "sweetalert2";
import { baseRoute } from '../services/baseUrl';
import $ from 'jquery';

export const ErrorHandling = (param,history) => {
    if ((param.status == "Failed" && param.code == 2) || (param.status == "Failed" && param.code == 3)) {
        localStorage.removeItem("CauseAdminToken");
        $('.modal').modal('hide');
        Swal.fire({
            title: param.status,
            text: "Your session has expired, please login again",
            icon: "warning",
        }).then(() => {
            history.push(`${baseRoute}admin`);
        });

    } else if ((param.status == "Failed" && param.code == 0) || (param.status == "Failed" && param.code == 1)) {
        localStorage.removeItem("CauseAdminToken");
        $('.modal').modal('hide');
        Swal.fire({
            title: param.status,
            text: "Please login first",
            icon: "warning",
        }).then(() => {
            history.push(`${baseRoute}admin`);
        });
    } else if (param["error-message"] != null) {
        let errorMessage = ``;
        let number = 1;
        $.each(param['error-message'], function(index, value) {
            if ( Object.keys(param['error-message']).length == 1 ) {
                errorMessage = errorMessage + value + `\n`;
            }else {
                errorMessage = errorMessage +number + '. ' + value + '<br>';
            }
            number++;
        })
        Swal.fire({
            title: param.status,
            html: errorMessage,
            icon: "warning",
        })
    } else {
        Swal.fire({
            title: param.status,
            text: param.message,
            icon: "warning",
        });
    }
};