import React from "react";
import { useEffect } from "react";
import { Link } from 'react-router-dom';
import { SidebarHandling, sidebarResize } from "./handling/SidebarHandling";

function Sidebar(props) {

    useEffect(() => {
        SidebarHandling();
        window.addEventListener("resize", sidebarResize);
        if (window.innerWidth < 992) {
            document.body.classList.remove("sidebar-open");
            document.body.classList.add("sidebar-closed", "sidebar-collapse");
        }

        return () => {
            window.removeEventListener("resize", sidebarResize); 
            document.body.classList.remove("sidebar-open", "sidebar-closed", "sidebar-collapse");
        }
    },[])

    return (
        <aside className="main-sidebar sidebar-grey-primary elevation-3" style={{ backgroundColor: '#F2F2F2' }}>
            <a href="https://cause.id/" className="brand-link" style={{ backgroundColor: 'white' }} target="_blank">
                <img src={process.env.PUBLIC_URL + '/img/logo-store-cause.png'} className="brand-image "/>
            </a>
            <div className="brand-text ">
                <a href="" className="brand-link" style={{ backgroundColor: 'white' }}></a>
            </div>
            
            <div className="sidebar">
                <nav className="mt-3 pb-6 mb-6 ">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        
                        <li className="nav-item">
                            <Link to="/admin/dashboard"
                                className={`nav-link ${props.path == '/admin/dashboard' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-chart-line"></i>
                                <p><strong>
                                        DASHBOARD
                                    </strong></p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/admin/bannershop"
                                className={`nav-link ${props.path == '/admin/bannershop' ? 'active' : ''} `}>
                                <i className="nav-icon fa fa-image"></i>
                                <p>
                                    <strong>
                                        BANNER SHOP
                                    </strong>
                                </p>
                            </Link>
                        </li>


                        <li className="nav-item">
                            <Link to="/admin/brands"
                                className={`nav-link ${props.path == '/admin/brands' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-archive"></i>
                                <p>
                                    <strong>BRAND</strong>
                                </p>
                            </Link>
                        </li>


                        <li className="nav-item">
                            <Link to="/admin/products"
                                className={`nav-link ${props.path == '/admin/products' || props.path == '/admin/productvariant/:idproduct' || props.path == '/admin/productattribute/:idproduct' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-briefcase"></i>
                                <p><strong>
                                        PRODUCT
                                    </strong></p>
                            </Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link to="/admin/promolist"
                                className={`nav-link ${props.path == '/admin/promolist' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-bullhorn"></i>
                                <p>
                                    <strong>
                                        PROMO
                                    </strong>
                                </p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/admin/promorecord"
                                className={`nav-link ${props.path == '/admin/promorecord' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-bullhorn"></i>
                                <p>
                                    <strong>
                                        PROMO RECORD
                                    </strong>
                                </p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/admin/orderspending"
                                className={`nav-link ${props.path == '/admin/orderspending' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-clock"></i>
                                <p><strong>
                                        ORDERS PENDING
                                    </strong></p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/admin/ordersprocessing"
                                className={`nav-link ${props.path == '/admin/ordersprocessing' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-cogs"></i>
                                <p><strong>
                                        ORDERS PROCESSING
                                    </strong></p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/admin/orderprocessed"
                                className={`nav-link ${props.path == '/admin/orderprocessed' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-shopping-cart"></i>
                                <p><strong>
                                        ORDERS PROCESSED
                                    </strong></p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/admin/ordersfinish"
                                className={`nav-link ${props.path == '/admin/ordersfinish' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-check-circle"></i>
                                <p><strong>
                                        ORDERS FINISH
                                    </strong></p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/admin/sales"
                                className={`nav-link ${props.path == '/admin/sales' ? 'active' : ''} `}>
                                <i className="nav-icon fas fa-shopping-cart"></i>
                                <p><strong>
                                        SALES
                                    </strong></p>
                            </Link>
                        </li>

                    </ul>

                </nav>

            </div>

        </aside>
    );
}
export default Sidebar;
