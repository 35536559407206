import React, { useState, useEffect } from 'react';
import { CartContext } from './Context';
import { AxiosPost, AxiosGet } from "../services/axios-services";


export default function CartContextWrapper(props) {

    const [cart,setCart] = useState(null)

    useEffect(() => {
    }, [cart])

    useEffect(() => {
      getCart()
    }, [])

    const addCartContext = (amount) =>{
      setCart(cart+amount)
    }
    const deleteCartContext = (amount) =>{
      setCart(cart-1)
    }

    const addCartPerItem = () => {
      setCart(cart +1)
    }

    const getCart = () =>{
        let userId = localStorage.getItem('_a7as__tt56uid'); 
        let param = {
            apiUrl: `/countcartbyiduser/${userId}`,
            apiConfig: {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('__xtropt') }
            },
        };
        AxiosGet(param, function (response) {
            if (response.status == "Success") {
                setCart(response.data);
            } else if(response.status == "Warning"){
                setCart(0);
            } else {
            }
        });
      return cart
    }
    const getCartState = () =>{
      return cart
    }
    
    return (
        <CartContext.Provider value={{getCart,getCartState,addCartContext,deleteCartContext,addCartPerItem}}>
          {props.children}
        </CartContext.Provider>
      );
}