import React from "react";
import { Route, Redirect } from "react-router-dom";
import { baseRoute } from './baseUrl';

export const ProtectedRouteAdmin = (props) => {
    return (
        <Route path={props.path} render={() => {
            return localStorage.hasOwnProperty('CauseAdminToken')
            ? <props.component {...props}></props.component>
            : <Redirect to={ `${baseRoute}admin` } />
        }} />
    )
}

export const ProtectedRouteUser= (props) => {
    return (
        <Route path={props.path} render={() => {
            return localStorage.hasOwnProperty('__xtropt')
            ? <props.component {...props}></props.component>
            :   
            <Redirect
                to={{
                    pathname: `${baseRoute}login`,
                    pageDestination : props.path
                }}
            />
        }} />
    )
}

export const ProtectedRouteShippingPayment= (props) => {

    return (
        <Route path={props.path} render={() => {
            return localStorage.hasOwnProperty('__xtropt')
            ?
                (
                    <props.component {...props} ></props.component>
                    // props.computedMatch.params.orderId ?
                    // <props.component {...props}></props.component> :
                    // <Redirect to={ `${baseRoute}orderlist` } />
                )
            : <Redirect to={ `${baseRoute}login` } />
        }} />
    )
}