// // cause.monster URL 
export const baseURL = 'https://api.cause.monster';
export const baseRoute = '/';
export const imageURL = 'https://img.cause.monster';

// IP Adress cause.monster URL 
// export const baseURL = 'https://52.74.77.24';
// export const baseRoute = '/';

// cause.id URL
// export const baseURL = 'https://api.cause.id';
// export const baseRoute = '/';
// export const imageURL = 'https://img.cause.id';

// local URL
// export const baseURL = 'http://192.168.100.223/api-backend/framework';
// export const baseURL = 'http://192.168.8.101/api-backend/framework';
// export const baseRoute = '/';
