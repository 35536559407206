import AxiosConfig from "../services/axios-config";

export const AxiosPost = async (param, callback) => {
    await AxiosConfig.post(param.apiUrl, param.apiData, param.apiConfig)
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            error.hasOwnProperty('response') ?
            callback(error.response.data) :
            callback(error);
        });
};

export const AxiosPut = async (param, callback) => {
    await AxiosConfig.put(param.apiUrl, param.apiData, param.apiConfig)
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            error.hasOwnProperty('response') ?
            callback(error.response.data) :
            callback(error);
        });
};

export const AxiosGet = async (param, callback) => {
    await AxiosConfig.get(param.apiUrl, param.apiConfig)
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            error.hasOwnProperty('response') ?
            callback(error.response.data) :
            callback(error);
        });
};

export const AxiosDelete = async (param, callback) => {
    await AxiosConfig.delete(param.apiUrl, param.apiConfig)
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            error.hasOwnProperty('response') ?
            callback(error.response.data) :
            callback(error);
        });
};
