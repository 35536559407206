import React from "react";

export const Loader = () => (
    <div id="loader-page" className="modal-loader"></div>
);

export const LoaderFunction = async () => {
    // document.getElementById("root").classList.add("fade-in");
    // document.getElementById("loader-page").classList.add("fade-out");
    await setTimeout(function () {
        document.getElementById("loader-page").classList.remove("modal-loader");
    }, 3000);
};

export const OffLoader = async () => {
    // document.getElementById("root").classList.add("fade-in");
    // document.getElementById("loader-page").classList.add("fade-out");
    await setTimeout(function () {
        document.getElementById("loader-page").classList.remove("modal-loader");
    }, 500);
};