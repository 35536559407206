import React from "react";

export const SidebarHandling = () => {

    let overlay = document.createElement("div");
    overlay.setAttribute("id", "sidebar-overlay");
    document.getElementsByClassName("wrapper")[0].appendChild(overlay);

    if (window.innerWidth < 992) {
        document.body.classList.add("sidebar-closed", "sidebar-collapse");
        document.getElementById("toogle-hide-sidebar").onclick = function () {
            document.body.classList.remove(
                "sidebar-closed",
                "sidebar-collapse"
            );
            document.body.classList.add("sidebar-open");
        };
        document.getElementById("sidebar-overlay").onclick = function () {
            document.body.classList.remove("sidebar-open");
            document.body.classList.add("sidebar-closed", "sidebar-collapse");
        };
    } else {
        document.getElementById("toogle-hide-sidebar").onclick = function () {
            document.body.classList.contains("sidebar-collapse")
                ? document.body.classList.remove("sidebar-collapse")
                : document.body.classList.add("sidebar-collapse");
        };
    }
    
};

export const sidebarResize = () => {
    if (window.innerWidth < 992) {
        // document.body.classList.contains('sidebar-open') && window.innerWidth < 992 ?
        document.body.classList.remove("sidebar-open");
        document.body.classList.add("sidebar-closed", "sidebar-collapse");
        document.getElementById(
            "toogle-hide-sidebar"
        ).onclick = function () {
            document.body.classList.remove(
                "sidebar-closed",
                "sidebar-collapse"
            );
            document.body.classList.add("sidebar-open");
        };
        document.getElementById("sidebar-overlay").onclick = function () {
            document.body.classList.remove("sidebar-open");
            document.body.classList.add(
                "sidebar-closed",
                "sidebar-collapse"
            );
        };
    } else {
        document.body.classList.remove(
            "sidebar-closed",
            "sidebar-collapse",
            "sidebar-open"
        );
        document.getElementById(
            "toogle-hide-sidebar"
        ).onclick = function () {
            document.body.classList.contains("sidebar-collapse")
                ? document.body.classList.remove("sidebar-collapse")
                : document.body.classList.add("sidebar-collapse");
        };
    }
}
