import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { AxiosPut } from "../../../services/axios-services";
import { ErrorHandling } from "../../../services/ErrorHandlingAdmin";
import Swal from "sweetalert2";
import $ from 'jquery';

const schema = yup.object().shape({
    attribute: yup.string().required('This field is required'),
    attribute_defaults: yup.string().required('This field is required'),
    attribute_types: yup.string().required('This field is required'),
});

function ModalAttributeEdit ({refresh,idProduct,dataAttribute}) {
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    const { register, handleSubmit, reset, clearErrors, formState:{ errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            attribute : '',
            attribute_types : '',
            attribute_defaults : '',
        },
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {

        data.new_attribute = data.attribute;
        data.attribute = dataAttribute.attribute;
        setLoader(true);
    
        let param = {
            apiUrl: `/product/attributes/update/${idProduct}`,
            apiData: data,
            apiConfig: {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('CauseAdminToken')}
            },
        };

        AxiosPut(param, function (response) {
            
            $('#modal-attribute-edit').modal('hide');
            setLoader(false);
            reset();
            clearErrors();
            
            if(response.status == 'Success') {
                refresh();
                Swal.fire({
                    title: "Success",
                    text: response.message,
                    icon: "success",
                    confirmButtonText: "Ok",
                })
            }else {
                ErrorHandling(response,history)
            }
        });
    }

    useEffect(() => {

        $('#modal-attribute-edit').on('hidden.bs.modal', function (e) {
            clearErrors();
            reset({
                attribute: dataAttribute && dataAttribute.attribute,
                attribute_defaults: dataAttribute && dataAttribute.default,
                attribute_types: dataAttribute && dataAttribute.uitype
            });
        })

        reset({
            attribute: dataAttribute && dataAttribute.attribute,
            attribute_defaults: dataAttribute && dataAttribute.default,
            attribute_types: dataAttribute && dataAttribute.uitype
        });

    },[dataAttribute])

    return (
        <div className="modal fade" id="modal-attribute-edit" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-danger">
                    <h5 className="modal-title">Update Attribute</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form  onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-body">
                        <div className="row form-group mb-1">
                            <div className="col-lg-4 col-sm-4 col-md-4">Attribute Lable</div>
                            <div className="col-lg-1 col-sm-1 col-md-1">:</div>
                            <div className="col-lg-7 col-sm-7 col-md-7">
                                <input type="text" className={ (errors.attribute) ? 'form-control is-invalid' : 'form-control' } 
                                {...register("attribute")} autoComplete="off" 
                                />
                                { (errors.attribute) ? <div className="invalid-feedback">{errors.attribute.message}</div> : null}
                            </div>
                        </div> 
                        <div className="row form-group mb-1">
                            <div className="col-lg-4 col-sm-4 col-md-4">Attribute Default Value</div>
                            <div className="col-lg-1 col-sm-1 col-md-1">:</div>
                            <div className="col-lg-7 col-sm-7 col-md-7">
                                <input type="text" className={ (errors.attribute_defaults) ? 'form-control is-invalid' : 'form-control' } 
                                {...register("attribute_defaults")} autoComplete="off" 
                                />
                                <input type="hidden" {...register("product_id")} />
                                { (errors.attribute_defaults) ? <div className="invalid-feedback">{errors.attribute_defaults.message}</div> : null}
                            </div>
                        </div> 
                        <div className="row form-group mb-1">
                            <div className="col-lg-4 col-sm-4 col-md-4">Attribute UI Type</div>
                            <div className="col-lg-1 col-sm-1 col-md-1">:</div>
                            <div className="col-lg-7 col-sm-7 col-md-7">
                                <select className={ (errors.attribute_types) ? 'form-control is-invalid' : 'form-control' } {...register("attribute_types")} >
                                    <option value=""> -- Select UI Type -- </option>
                                    <option value="thumbnail"> Thumbnail </option>
                                    <option value="dropdown"> Dropdown </option>
                                </select>
                                { (errors.attribute_types) ? <div className="invalid-feedback">{errors.attribute_types.message}</div> : null}
                            </div>
                        </div> 

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-danger">
                            {!loader ? ('Save') : (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div> 
    )
}

export default ModalAttributeEdit;