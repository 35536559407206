import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Loader, LoaderFunction } from "../../components/Loader";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import Footer from "../../components/admin/Footer";
import ModalAddAttribute from "../../components/admin/modal/ModalProductAttributeAdd";
import ModalEditAttribute from "../../components/admin/modal/ModalProductAttributeEdit";
import { AxiosPost, AxiosGet } from "../../services/axios-services";
import { ErrorHandling } from "../../services/ErrorHandlingAdmin";
import { baseRoute } from "../../services/baseUrl";
import Swal from "sweetalert2";

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import StyleAdminLte from "../../components/style/StyleAdminLte";


function ProductAttribute(props) {

    const [productAttribute, setProductAttribute] = useState([]); 
    const [productAttributeEdit, setProductAttributeEdit] = useState([]); 
    const [ProductAttributeLoader, setProductAttributeLoader] = useState(false);
    const { SearchBar } = Search;

    const cellButton = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button className="btn btn-warning btn-xs mx-1 my-1" data-toggle="modal" data-target="#modal-attribute-edit" onClick={ () => setProductAttributeEdit(row) }>Edit</button>
                <button className="btn btn-danger btn-xs mx-1 my-1" onClick={(id) => deleteAttribute(row.attribute)}>Delete</button>
            </>
        );
    };
    
    const columns = [
        {
            dataField: 'no', text: 'No'
        }, 
        {
            dataField: 'attribute', text: 'Attribute Label'
        },
        {
            dataField: 'default', text: 'Default Value'
        },
        {
            dataField: 'uitype', text: 'UI Type'
        },
        {
            dataField: 'action', text: 'Action', formatter: cellButton
        }
    ];

    const history = useHistory();
    const {idproduct} = useParams();

    useEffect(() => {
        LoaderFunction();
        getProductAttributes();
    },[])

    function getProductAttributes() {
        setProductAttributeLoader(true);
        let param = {
            apiUrl: `/product/attributes/${idproduct}`,
            apiConfig: {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('CauseAdminToken')}
            },
        }
        AxiosGet(param, function (response) {
            if (response.status == 'Success') {
                attributePrepocessing(response.data);
                setProductAttributeLoader(false);
            } else {
                setProductAttributeLoader(false);
                ErrorHandling(response,history)
            }
        });
    }

    function attributePrepocessing(dataAttribute) {
        let tempAttribute = [];
        dataAttribute.attributes.map((attribute, index) => {
            tempAttribute.push({
                no: index+1,
                attribute: attribute,
                default: dataAttribute.defaults[index],
                uitype: dataAttribute.uitypes[index]
            });
        })

        setProductAttribute(tempAttribute);
    }

    function deleteAttribute(attribute) {
        let param = {
            apiUrl: `/product/attributes/delete/${idproduct}`,
            apiData: {attribute: attribute},
            apiConfig: {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('CauseAdminToken')}
            },
        }
        Swal.fire({
            title: 'Warning',
            text: 'Are you sure to delete this product attribute?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return AxiosPost(param, function (response) {
                    if(response.status != 'Success') {
                        ErrorHandling(response,history)
                    }
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                    title: 'Success',
                    text: 'Success delete data attribute',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    getProductAttributes();
                }) 
              }
          })
    }

    return (
        <React.Fragment>
            <Loader />
            <StyleAdminLte />
            <div className="wrapper">
                <Navbar />
                <Sidebar  {...props} />
                <div className="content-wrapper">
                    {/* Content Header (Page header)  */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Admin</li>
                                        <li className="breadcrumb-item active">Product</li>
                                        <li className="breadcrumb-item active">Attribute</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="card card-danger card-outline" style={{ minHeight : "720px" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <h6>Product Attribute</h6>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-danger btn-sm float-right" data-toggle="modal" data-target="#modal-attribute-edit">Add Attribute</button>
                                            <button className="btn btn-danger btn-sm float-right mr-2" onClick={ () => history.push({pathname: `${baseRoute}admin/products`})}>Back</button>
                                        </div>
                                    </div><hr/>
                                    <div className="row">
                                        {
                                            ProductAttributeLoader ? 
                                            (
                                            <div className="col-md-12 col-sm-12 text-center">
                                                <span className="spinner-border spinner-border-lg text-danger" role="status" aria-hidden="true"></span>
                                                <h6 className="text-danger">Loading ...</h6>
                                            </div>
                                            ) : ProductAttribute.length == 0 ?
                                                (
                                                    <div className="col-md-12 col-sm-12 text-center">
                                                        <center><h5>No products data found</h5></center>
                                                    </div>
                                                ): 
                                                (
                                                    <ToolkitProvider
                                                    keyField='no' 
                                                    data={ productAttribute } 
                                                    columns={ columns }
                                                    search >
                                                        {
                                                            props => (
                                                                <div>
                                                                    <SearchBar className="col-sm-2" { ...props.searchProps } />
                                                                    <BootstrapTable
                                                                    { ...props.baseProps }
                                                                    striped hover condensed
                                                                    pagination={ paginationFactory()}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider>
                                                )
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                    
                </div>
                <Footer/>
            </div>

            <ModalAddAttribute refresh={() => getProductAttributes()} idProduct={idproduct} />
            <ModalEditAttribute refresh={() => getProductAttributes()} dataAttribute={productAttributeEdit} idProduct={idproduct} />
        </React.Fragment>
    );
}

export default ProductAttribute;
