import React, {lazy, Suspense} from "react";
import { BrowserRouter, Link, Route, Switch, Redirect } from "react-router-dom";
import { ProtectedRouteAdmin, ProtectedRouteUser, ProtectedRouteShippingPayment } from "./services/protected-routes";
import { baseRoute } from './services/baseUrl';
import ProductAttribute from "./views/admin/ProductAttribute";

import Loader from './components/LoaderNew'



const Home = lazy(() => import("./views/store/Home"));
const Product = lazy(() => import("./views/store/Product"));
// const DetailProduct = lazy(() => import('./components/store/product/DetailProduct'));
const DetailProduct = lazy(() => import('./components/store/product/DetailProduct'));
const Search = lazy(() => import("./views/store/Search"));
const Cart = lazy(() => import("./views/store/Cart"));
const Shipping = lazy(() => import("./views/store/Shipping"));
const Payment = lazy(() => import("./views/store/Payment"));
const Address = lazy(() => import('./views/store/Address/Address'))
const DetailOrder = lazy(() => import("./views/store/DetailOrder"));
// const OrderList = lazy(() => import("./views/store/OrderList"));
const OrderList = lazy(() => import("./views/store/OrderListV2"));
const Tracking = lazy(() => import("./views/store/Tracking"));
const LoginUser = lazy(() => import("./views/login/LoginUser"));
const LoginAdmin = lazy(() => import("./views/login/LoginAdmin"));
const Dashboard = lazy(() => import("./views/admin/Dashboard"));
const Brands = lazy(() => import("./views/admin/Brands"));
const ProductsAdmin = lazy(() => import("./views/admin/Products"));
const ProductVariantAdmin = lazy(() => import("./views/admin/ProductVariant"));
const ProductSpecAdmin = lazy(() => import("./views/admin/ProductSpec"));
const ProductVariantAttribute = lazy(() => import("./views/admin/ProductAttribute"));
const OrdersProcessing = lazy(() => import("./views/admin/OrdersProcessing"));
const OrdersPending = lazy(() => import("./views/admin/OrdersPending"));
const OrdersFinish = lazy(() => import("./views/admin/OrdersFinish"));
const Sales = lazy(() => import("./views/admin/Sales"));
const NotFound = lazy(() => import("./views/NotFound"));
const RefundPolicy = lazy(() => import("./views/refund_policy"))
const BannerShop = lazy(() => import('./views/admin/banner_shop/banner_shop'))
const PromoCode = lazy(() => import('./views/admin/promo/promolist'))
const Faq = lazy(() => import('./views/faq'));
const PromoRecord = lazy(() => import('./views/admin/promo_record/promo_record'))
const OrderProcessed = lazy(() => import('./views/admin/order_processed/order_processed'))
const ShippingRedeem = lazy(() => import('./views/store/Redeem/ShippingRedeem'))
const SummaryRedeem = lazy(() => import('./views/store/Redeem/SummaryRedeem'))

const Main = (props) => (
    <Suspense fallback={<Loader/>}>
        <Switch>
            {/* Routes Store */}
            <Route exact path={`${baseRoute}`} component={Home} /> 
            <Route exact path={`${baseRoute}product/:brand`} component={Product} /> 
            <Route exact path={`${baseRoute}search/:word`} component={Search} /> 
            <Route exact path={`${baseRoute}detail-product/:productId`} component={DetailProduct}></Route>
            <ProtectedRouteUser exact path={`${baseRoute}cart`} component={Cart} /> 
            <ProtectedRouteUser exact path={`${baseRoute}shipping/:orderNumber`} component={Shipping} />
            {/* <Redirect from={`${baseRoute}shipping`} to={`${baseRoute}orderlist`} />  */}
            <ProtectedRouteUser exact path={`${baseRoute}payment/:orderNumber`} component={Payment} />
            <Redirect from={`${baseRoute}payment`} to={`${baseRoute}orderlist`} />
            <ProtectedRouteUser exact path={`${baseRoute}detail/:orderNumber`} component={DetailOrder} />
            <ProtectedRouteUser exact path={`${baseRoute}orderlist/:status`} component={OrderList} /> 
            <Redirect from={`${baseRoute}orderlist`} to={`${baseRoute}orderlist/all`} /> 
            <ProtectedRouteUser exact path={`${baseRoute}trackshipping/:idTracking`} component={Tracking} /> 
            <Redirect from={`${baseRoute}trackshipping`} to={`${baseRoute}orderlist`} />
            <ProtectedRouteUser exact path={`${baseRoute}address`} component={Address} />
            <ProtectedRouteUser exact path={`${baseRoute}redeem-shipping/:orderNumber`} component={ShippingRedeem} />
            <ProtectedRouteUser exact path={`${baseRoute}summary-redeem/:orderNumber`} component={SummaryRedeem} />

            {/* Routes Admin */}
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/dashboard`} component={Dashboard} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/brands`} component={Brands} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/products`} component={ProductsAdmin} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/productvariant/:idproduct`} component={ProductVariantAdmin} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/productspec/:idproduct`} component={ProductSpecAdmin} /> 
            <Redirect from={`${baseRoute}admin/productvariant`} to={`${baseRoute}admin/products`} />  
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/productattribute/:idproduct`} component={ProductAttribute} /> 
            <Redirect from={`${baseRoute}admin/productattribute`} to={`${baseRoute}admin/products`} />  
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/orderspending`} component={OrdersPending} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/ordersprocessing`} component={OrdersProcessing} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/ordersfinish`} component={OrdersFinish} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/sales`} component={Sales} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/bannershop`} component={BannerShop} /> 
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/promolist`} component={PromoCode} />
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/promorecord`} component={PromoRecord} />
            <ProtectedRouteAdmin exact path={`${baseRoute}admin/orderprocessed`} component={OrderProcessed} />

            {/* Routes Authentication */}
            <Route exact path={`${baseRoute}admin`} component={LoginAdmin} />
            <Route exact path={`${baseRoute}login`} component={LoginUser} /> 
            <Route exact path={`${baseRoute}refund-policy`} component={RefundPolicy} />
            <Route exact path={`${baseRoute}faq`} component={Faq} />

            {/* Page Not Found 404 */}
            <Route exact path="*" component={NotFound} /> 
        </Switch>
    </Suspense>

);
export default Main;
