import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LogoutHandling } from "./handling/LogoutHandling";
import modal from "../../utility/modal";
import Swal from "sweetalert2";
import { AxiosPost } from "../../services/axios-services";
import { ErrorHandling } from "../../services/ErrorHandlingAdmin";


function Navbar(props) {

    let history = useHistory();
    useEffect(() => {
        // const initClient = () => {
        //     gapi.client.init({
        //         clientId: modal.clientId,
        //         scope: 'email'
        //     });
        // };
        // gapi.load('client:auth2', initClient);
        // const SCOPE = "TODO: your scope here";
        // const handleClientLoad = () => window.gapi.load('client:auth2', initClient);
    
        // const initClient = () => {
        //     window.gapi.client.init({
        //         'clientId': modal.clientId,
        //         'scope': SCOPE
        //     });
        //     console.log("Google loaded");
        // };

        // const script = document.createElement('script');

        // script.src = "https://apis.google.com/js/api.js";
        // script.async = true;
        // script.defer = true;
        // script.onload = handleClientLoad;

        // document.body.appendChild(script);

        // return () => {
        //     document.body.removeChild(script);
        // };
    }, [])
    function logOut(){
        Swal.fire({
            title: 'Warning',
            text: 'Are you sure logout?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                let param = {
                    apiUrl: "/logoutnadmin",
                    apiData: null,
                    apiConfig: {
                        headers: {'Authorization': 'Bearer ' + localStorage.getItem('CauseAdminToken')}
                    },
                };
    
                return AxiosPost(param, function (response) {
                    if(response.status == 'Success') {
                        localStorage.removeItem('CauseAdminToken');
                        localStorage.removeItem('statusLogin')
                        return response;
                    }else {
                        ErrorHandling(response,history)
                    }
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
              if (result.isConfirmed) {
                history.push("/admin");
              }
          })
    }
    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light shadow-sm">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                    <a id="toogle-hide-sidebar" className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></a>
                </li>
            </ul>
            <ul className="navbar-nav">
                {/* <GoogleLogout clientId={modal.clientId} buttonText="Log out" onLogoutSuccess={logOut} ></GoogleLogout>    */}
                <button onClick={logOut} className="btn btn-danger btn-sm"> <i className="fa fa-arrow-circle-left"></i> Logout</button>
            </ul>
            <br/>
        </nav>
    );
}
export default Navbar;
