// local
// const url = 'http://192.168.8.101/api-backend/framework/'
// const url = 'http://192.168.100.88/api-backend/framework/'

// monster develop
// const url = 'https://api.cause.monster/framework/'

// production
const url = `https://api.cause.id/framework/`;

export default {
    baseUrl : url,
    // clientId : '1072547010459-ce2jeik9easdkvtctpk7nrf61u17adrf.apps.googleusercontent.com',
    clientId : '351597951395-4oi10rg3s2gdsala5446usfqnr6qba5e.apps.googleusercontent.com',
    loginWithGoogle : `${url}auth/login_with_google`,
    keyMaterial : 'TrTtw7PHkcc',
    ivMaterial : 'TrTtw7PHkcc',
}
